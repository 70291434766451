import { cookieExtractor } from "@shared/helpers/cookieExtractor";
import { useSearchParams } from "@shared/react-router-dom";
import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const UTM_KEY = "latest_utms";
const ATTRIBUTION_KEY = "initial_attribution";
const COOKIE_EXP_OFFSET = 30 * 24 * 60 * 60; // 30 days in seconds
const UTM_PARAMETERS = [
  "utm_source",
  "utm_id",
  "utm_term",
  "utm_content",
  "utm_channel",
  "utm_adgroup",
  "utm_matchtype",
  "utm_device",
  "utm_campaign",
  "utm_medium",
];

const CLICK_IDS = ["gclid", "fbclid", "msclkid"];
const INITIAL_ATTRIBUTION_PARAMS = CLICK_IDS.concat(UTM_PARAMETERS);

const convertToCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

const getReferrerSearchParams = () => {
  if (document.referrer) {
    const referrerUrl = new URL(document.referrer);

    return new URLSearchParams(referrerUrl.search);
  }

  return new URLSearchParams();
};

const getCookieValue = (key: string): Record<string, string> => {
  const cookieValue = cookieExtractor([key])?.[key];
  try {
    return JSON.parse(atob(decodeURIComponent(cookieValue)));
  } catch {
    return {};
  }
};

const storeValuesInCookie = (values: Record<string, string>, key: string) => {
  const expirationDate = new Date(Date.now() + COOKIE_EXP_OFFSET * 1000).toUTCString();
  const encodedUTMs = btoa(JSON.stringify(values));
  document.cookie = `${key}=${encodedUTMs}; expires=${expirationDate}; path=/;`;
};

const getAllUrlParams = (searchParams: URLSearchParams): Record<string, string> => {
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

const getAttributionParams = (urlParams?: URLSearchParams) => {
  if (isSSR()) return {};

  const searchParams = urlParams || new URLSearchParams(window.location.search);
  const referrerSearchParams = getReferrerSearchParams();
  const attributionCookie = getCookieValue(ATTRIBUTION_KEY);
  const latestUtmsCookie = getCookieValue(UTM_KEY);

  const currentParams = INITIAL_ATTRIBUTION_PARAMS.reduce(
    (acc, param) => {
      const value = searchParams.get(param) || referrerSearchParams.get(param);
      if (value) acc[param] = value;

      return acc;
    },
    {} as Record<string, string>
  );

  const hasNewValues = Object.values(currentParams).some(value => !!value);
  if (hasNewValues) {
    storeValuesInCookie(currentParams, UTM_KEY);

    const allParams = {
      ...getAllUrlParams(referrerSearchParams),
      ...getAllUrlParams(searchParams),
    };
    storeValuesInCookie(allParams, ATTRIBUTION_KEY);
  }

  const combinedData = {
    ...latestUtmsCookie,
    ...attributionCookie,
    ...currentParams,
  };

  return INITIAL_ATTRIBUTION_PARAMS.reduce(
    (acc, param) => {
      if (combinedData[param]) {
        acc[param] = combinedData[param];
      }

      return acc;
    },
    {} as Record<string, string>
  );
};
const useUnauthUrlFields = ({ isCamelCaseKeys } = { isCamelCaseKeys: false }) => {
  const [searchParams] = useSearchParams();
  const payload = getAttributionParams(searchParams);

  if (isCamelCaseKeys) {
    return Object.fromEntries(
      Object.entries(payload).map(([key, value]) => [convertToCamelCase(key), value])
    );
  }

  return payload;
};

export { useUnauthUrlFields, getAttributionParams };
